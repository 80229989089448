<template>
  <div class="emoji" :class="customClass">
    <span @click="isShow = !isShow" class="emoji__btn">
      <img src="@/assets/img/icons/emoji-face.png" alt="" />
    </span>
    <div class="emoji__picker" v-if="isShow">
      <Picker :data="emojiIndex" set="apple" @select="showEmoji" />
    </div>
  </div>
</template>

<script>
import data from "emoji-mart-vue-fast/data/all.json";
import "emoji-mart-vue-fast/css/emoji-mart.css";

import { Picker, EmojiIndex } from "emoji-mart-vue-fast";

let emojiIndex = new EmojiIndex(data);

export default {
  name: "EmojiPicker",
  components: {
    Picker,
  },
  props: {
    content: {
      type: String,
      default: "",
    },
    inputRef: {
      type: Object,
      default: () => {},
    },
    customClass: {
      type: String,
      default: "",
    },
    isCustomElement: {
      type: Boolean,
      default: false,
    },
    cursorPositionInCustomElement: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      isShow: false,
      emojiIndex: emojiIndex,
      valueOutput: "",
    };
  },
  methods: {
    insertEmoji(str, index, value) {
      return str.substr(0, index) + value + str.substr(index);
    },
    setSelectionRange(input, selectionStart, selectionEnd) {
      if (input.setSelectionRange) {
        input.focus();
        this.$nextTick(() => {
          this.inputRef.$refs.input.setSelectionRange(
            selectionStart + 1,
            selectionEnd + 1
          );
        });
      }
    },
    showEmoji(emoji) {
      if (this.isCustomElement) {
        this.$emit(
          "newValue",
          this.insertEmoji(
            this.content,
            this.cursorPositionInCustomElement,
            emoji.native
          )
        );
        this.$emit(
          "setLastPositionOnCustomElement",
          this.cursorPositionInCustomElement
        );
      } else {
        const input = this.inputRef.$refs.input;
        this.valueOutput = this.insertEmoji(
          this.content,
          input.selectionEnd,
          emoji.native
        );
        this.$emit("newValue", this.valueOutput);
        this.isShow = false;
        this.setSelectionRange(
          input,
          input.selectionStart + emoji.native.length,
          input.selectionEnd + emoji.native.length
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.emoji {
  position: relative;
  width: 15px;
  z-index: 2;
  &__btn {
    display: block;
    width: 18px;
    height: 18px;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
  &__picker {
    position: absolute;
    right: 15px;
    top: 0px;
  }
  .emoji-mart {
    width: 100% !important;
    max-width: 300px;
  }
  &.chat {
    .emoji {
      &__picker {
        right: 0 !important;
        bottom: 100% !important;
        top: inherit !important;
      }
    }
  }
}
</style>
